<template>
  <div>
    <div class="block" v-for="(item ,index) in isData" :key="index">
      <div class="img"
           @click="openImg(item)">
        <img
            :src="item.ImageUrl"></div>
      <div class="number">编号：{{ item.WorkNumber }}</div>
      <div class="user">作者：{{ item.UserName }}</div>
      <div class="title">标题：{{ item.PenName }}</div>
      <div class="time">时间：{{ item.Time }}</div>
      <div class="cham">
        <i v-bind:class="{ones:index==0,twos:index==1,threes:index==2,oneT:item.CollectInShow&&index==0,twoT:item.CollectInShow&&index==1,threeT:item.CollectInShow&&index==2}"
           v-if="index==0||index==1||index==2" @click="work(item.id)"></i>
        <i v-else class="like" v-bind:class="{Ts:item.CollectInShow}" @click="work(item.id)"></i>
        <span class="CollectNumber">{{ item.Collect }}</span>
      </div>
    </div>
    <Details ref="Details"></Details>
  </div>
</template>

<script>
import Details from "../../../../components//Details";
import {setkork} from "@/api/exhibit";
import {Notify, Toast} from "vant";

export default {
  props: {
    isData: []
  },
  components: {
    Details
  },
  methods: {
    // 打开详情
    openImg(SidNumber) {
      this.$refs.Details.openImg(SidNumber)
    },
    //点赞
    work(WorkID) {
      let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
      let ActivityID = ActivityInfo.id
      let UserID = this.$store.state.userInfo.id
      let CompanyID = JSON.parse(localStorage.getItem("CompanyID"))

      setkork({WorkID, UserID, ActivityID, CompanyID}).then(res => {
        if (res.errorCode == 200) {
          Toast.success(res.payload);
          this.$parent.uppopularCol()
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.block {
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 5px;
  @mixin pos {
    position: absolute;
    font-size: 11px;
  }

  .img {
    position: absolute;
    width: 60px;
    height: 85px;
    left: 30px;
    top: 6px;
    overflow: hidden;
    border: 1px solid #f0f0f0;

    img {
      //transform: rotate(90deg);
      width: 100%;
      height: 100%;
    }
  }

  .number {
    @include pos;
    left: 100px;
    top: 10px;
  }

  .title {
    @include pos;
    left: 100px;
    top: 50px;
  }

  .time {
    @include pos;
    left: 100px;
    top: 70px;
  }

  .user {
    @include pos;
    left: 100px;
    top: 30px;
    width: 120px;
  }

  .cham {
    @include pos;
    left: 260px;
    top: 10px;
    width: 75px;
    height: 25px;
    line-height: 25px;

    i {
      width: 17px;
      height: 17px;
      background-size: 100% 100%;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 5px;
    }

    .T {
      background-image: url("../../../../assets/image/create/like.png");
    }

    .Ts {
      background-image: url("../../../../assets/image/create/likes.png")!important;
    }

    .ones {
      background-image: url("../../../../assets/image/exhibit/chamOnes.png");
    }

    .twos {
      background-image: url("../../../../assets/image/exhibit/chamTwos.png");
    }

    .threes {
      background-image: url("../../../../assets/image/exhibit/chamThrees.png");
    }

    .oneT {
      background-image: url("../../../../assets/image/exhibit/chamOne.png");
    }

    .twoT {
      background-image: url("../../../../assets/image/exhibit/chamTwo.png");
    }

    .threeT {
      background-image: url("../../../../assets/image/exhibit/chamThree.png");
    }

    .like {
      background-image: url("../../../../assets/image/create/like.png");
    }

    .CollectNumber {
      position: absolute;
      left: 30px;
      top: 3px;
    }

  }

  .step {
    @include pos;
    left: 273px;
    top: 40px;


    i {
      margin-right: 10px;
      display: inline-block;
      background-image: url("../../../../assets/image/create/step.png");
      width: 17px;
      height: 17px;
      background-size: 100% 100%;
      float: left;
    }

    span {
      float: left;
      display: inline-block;
    }
  }

  .steps {
    i {
      background-image: url("../../../../assets/image/create/steps.png");
    }

  }
}
</style>