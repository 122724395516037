<template>
  <div class="search" v-if="view">
    <div class="searchtitle">
      <div class="block searchStart">搜索</div>
    </div>
    <div class="input">
      <input type="text" v-model="inputData"/>
      <div class="set" @click="setSearch"></div>
    </div>
    <div class="close" @click="closesearch"></div>
  </div>
</template>

<script>
import {findSearch} from "../../../../api/exhibit";
import {Toast} from "vant";

export default {
  data() {
    return {
      view: false,
      inputData: ''
    }
  },
  methods: {
    open() {
      this.view = true
    },
    setSearch() {
      let CompanyID = JSON.parse(localStorage.getItem("CompanyID"))
      let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
      this.ActivityInfo = ActivityInfo
      let ActivityID = ActivityInfo.id


      let inputData = this.inputData
      if (inputData) {
        findSearch({Data:inputData, CompanyID,ActivityID}).then(res => {
          this.inputData = ''
          if (res.errorCode == 200) {
            let data = res.payload
            if (data.length >= 1) {
              this.view = false
              this.$parent.openSearchList(data)
            } else {
              Toast('暂无数据');
            }
          } else {
            Toast(res.errorMsg);
          }
        })
      } else {
        Toast('请输入搜索关键词');
      }
    },
    closesearch() {
      this.view = false
      this.inputData = ''
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;

  .searchtitle {
    background: none;
    font-size: 15px;
    font-weight: 100;
    letter-spacing: 5px;
    color: #FFFFFF;
    box-shadow: none;
    position: absolute;
    width: 210px;
    height: 30px;
    line-height: 30px;
    margin-left: -140px;
    left: 50%;
    top: 80px;
    text-indent: 0;

    .block {
      display: inline-block;
      width: 100px;
      text-align: center;
      border: 2px solid #FFFFFF;
    }

    .searchStart {
      background-color: #DD7D7D
    }
  }

  .close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 50px;
    background-image: url("../../../../assets/image/exhibit/closes.png");
    background-size: 100% 100%;
  }

  .input {
    width: 280px;
    height: 40px;
    background-color: #000000;
    border: 1px solid #f0f0f0;
    position: absolute;
    margin-left: -140px;
    left: 50%;
    top: 135px;

    input {
      width: 230px;
      height: 100%;
      padding: 0;
      border: none;
      background: none;
      text-indent: 1rem;
      color: #f0f0f0;
      position: absolute;
      left: 0;
    }

    .set {
      width: 50px;
      height: 40px;
      background-size: 30px 30px;
      background-position: center center;
      background-image: url("../../../../assets/image/exhibit/rightSet.png");
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      border-left: 1px solid #f0f0f0;
    }
  }
}
</style>