<template>
 <div>
   <div class="PrizeRanking">
     <div class="block" v-for="(item,index) in data" :key="index">
       <div class="number">第{{index+1}}名</div>
       <div class="Avatar"><img :src="item.Avatar" alt=""></div>
       <div class="UserName">{{item.UserName}}</div>
       <div class="Collect">彩蛋数：<span>{{item.Collect}}</span></div>
     </div>
   </div>
    <Load ref="Load"></Load>
 </div>
</template>

<script>
import {prizeRankingFind} from "@/api/exhibit";
import {Notify} from "vant";
import Load from "../../../components/load";

export default {
  data(){
    return{
      data:[]
    }
  },
  mounted() {
    const CompanyID = localStorage.getItem("CompanyID");
    let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
    let ActivityID = ActivityInfo.id
    this.data = []
    this.$refs.Load.open()
    prizeRankingFind({CompanyID, ActivityID}).then(res => {
      this.$refs.Load.close()
      let payload = res.payload
      if (payload.start == '正常') {
        let data = payload.data
        for (let i in data){
          let UserName = data[i].UserName
          if (UserName.length >= 5) {
            data[i].UserName = UserName.slice(0, 5) + '...'
          }
        }
        this.data = data
      }else {
        Notify({type: 'warning', message:payload.start});
      }
    })
  },
  components:{Load}
}
</script>


<style scoped lang="scss">
.PrizeRanking{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .block{
    width: 90%;
    height: 60px;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    .number{
      position: absolute;
      font-size: 15px;
      font-weight: bold;
      left: 10px;
      top: 23px;
    }
    .Avatar{
      position: absolute;
      left: 75px;
      top: 5px;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .UserName{
      position: absolute;
      font-size: 14px;
      left: 130px;
      top: 22px;
    }
    .Collect{
      position: absolute;
      font-size: 14px;
      left: 210px;
      top: 19px;
      span{
        font-size: 20px;
        font-weight: bold;
        color: #8c0c63;
      }
    }
  }
}
</style>