<template>
  <div class="GuidePage" v-if="view">
    <div class="main">
      <img :src="ImageUrl">
      <div class="button" @click="closeGuides">知道了</div>
      <div class="checkbox">
        <input type="checkbox" v-model="GuideStart"/>下次不再提示
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      view: false,
      ImageUrl: 'https://3qlab-wrth.oss-cn-hangzhou.aliyuncs.com/image/2024-2-1/%E6%9A%97%E5%BA%95%E5%BC%95%E5%AF%BC%E9%A1%B5%E9%95%BF%E5%9B%BE%E6%8B%BC%E6%8E%A53.jpg',
      GuideStart: false,
    }
  },
  mounted() {
    this.guideFind()
  },
  methods: {
    //引导页检查
    guideFind() {
      let GuidePage = localStorage.getItem("GuidePage")
      if (!GuidePage) {
        this.view = true
      }
    },
    //关闭引导页
    closeGuides() {
      let GuideStart = this.GuideStart
      if (GuideStart) {
        localStorage.setItem("GuidePage", 1)
        this.view = false
      } else {
        this.view = false
      }
    },
  }

}

</script>

<style scoped lang="scss">
.GuidePage {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  .main {
    width: 280px;
    height: 480px;
    background-color: #FFFFFF;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -140px;
    margin-top: -280px;
    overflow-y: auto;

    img {
      width: 100%;
      height: auto;
    }

    .button {
      position: fixed;
      width: 120px;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      background-color: #8c0c63;
      bottom: 70px;
      left: 50%;
      margin-left: -60px;
      text-align: center;
      color: #fff;
      font-weight: bold;
      border-radius: 10px;
      border: 2px solid #fff;
    }

    .checkbox {
      position: fixed;
      font-size: 14px;
      bottom: 40px;
      left: 50%;
      margin-left: -60px;
      text-align: center;
      color: #fff;
    }
  }
}
</style>