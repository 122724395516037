<template>
  <!--  活动介绍与公告-->
  <div class="introduce">
    <div class="data" v-html="data"></div>
    <Load ref="Load"></Load>
  </div>
</template>

<script>
import Load from "../../../components/load";


export default {
  data() {
    return {
      view: false,
      data: ''
    }
  },
  components:{
    Load
  },
  methods: {
    getdata() {
      this.$refs.Load.open()
    }
  }
}
</script>

<style scoped lang="scss">
.introduce {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  overflow-y: scroll;
  font-size: 14px;
  line-height: 35px;

  .fontBrod {
    font-weight: bold;
    font-size: 17px;
  }

  .font {
    font-weight: bold;
  }

  .data {
    width: 90%;
    margin: 0 auto;

    p {
      text-indent: 2rem;
    }

    .center {
      text-align: center;
    }

    .red {
      color: #c00000;
      font-weight: bold;
    }

    .bule {
      font-weight: bold;
      color: #0000ff;
    }
  }

  .image_Qr {
    width: 100px;
    margin-right: 15px;
  }

  .overline {
    text-decoration: underline
  }

  ul {
    width: 90%;
    margin: 0 auto;
  }

  ul li {
    list-style-type: disc;
  }
}
</style>