<template>
  <div class="GuidePage" v-if="view">
    <div class="main">
      <h4>好消息！</h4>
      <p>
        天长全家福新年限时活动来啦！除夕至初二（2月9日至11日24：00）期间，上传属于你们家的全家福照片即可参与，我们将根据点赞榜前三名对最幸福的三个家庭分别发放999.99，666.66和333.33元的现金奖励。还等什么，快来参加吧！
      </p>
      <h4>
        温馨提示：
      </h4>
      <p> 1.本活动为限时活动，在且仅在活动时间内上传符合要求的全家福照片才可参与，请您注意时间。</p>
      <p>
        2.由于春节期间审核压力较大，每天审核为定时审核，最晚请于2月11日12时前完成上传，望您理解。最终统计点赞数截止2月11日24时。</p>
      <p> 3.在非活动时间内上传的照片不参与统计，若您之前已上传过自家全家福照片，可于活动期间再次上传。</p>
      <div class="button" @click="closeGuides">知道了</div>
      <div class="checkbox">
        <input type="checkbox" v-model="GuideStart"/>下次不再提示
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      view: false,
      GuideStart: false,
    }
  },
  mounted() {
    // this.guideFind()
  },
  methods: {
    //引导页检查
    guideFind() {
      let MessagePage = localStorage.getItem("MessagePage")
      if (!MessagePage) {
        this.view = true
      }
    },
    //关闭引导页
    closeGuides() {
      let GuideStart = this.GuideStart
      if (GuideStart) {
        localStorage.setItem("MessagePage", 1)
        this.view = false
      } else {
        this.view = false
      }
    },
  }

}

</script>

<style scoped lang="scss">
.GuidePage {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  .main {
    width: 280px;
    height: 480px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -140px;
    margin-top: -280px;
    overflow-y: auto;
    background-color: #8c0c63;

    h4{
      color: #fff;
      text-indent: 1rem;
    }
    p{
      font-size: 15px;
      line-height: 30px;
      color: #fff;
      width: 90%;
      margin: 0 auto;
      text-indent: 2rem;
    }

    img {
      width: 100%;
      height: auto;
    }

    .button {
      position: fixed;
      width: 120px;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      background-color: #8c0c63;
      bottom: 70px;
      left: 50%;
      margin-left: -60px;
      text-align: center;
      color: #fff;
      font-weight: bold;
      border-radius: 10px;
      border: 2px solid #fff;
    }

    .checkbox {
      position: fixed;
      font-size: 14px;
      bottom: 40px;
      left: 50%;
      margin-left: -60px;
      text-align: center;
      color: #fff;
    }
  }
}
</style>
