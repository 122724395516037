<template>
  <div class="list" ref="list">
    <div v-if="!popular">
      <TolWork ref="TolWork" :isData="data"></TolWork>
    </div>
    <div v-else>
      <RankingWork ref="RankingWork" :isData="data"></RankingWork>
    </div>
    <div class="addLoad" v-if="data.length>=1&&!popular">
      <span @click="LastLoad" class="page">上一页</span>
      <span class="pages">{{ skip }}/{{ pageTol }}</span>
      <span @click="NextLoad" class="page">下一页</span>
    </div>
    <div class="NotData" v-if="data.length==0" v-bind:style="NotData">
      暂无数据
    </div>
    <Load ref="Load"></Load>
    <Details ref="Details"></Details>
  </div>
</template>

<script>
import {popularFind, WorkFind, WorkFindById} from "../../../../api/exhibit";
import {Notify, Toast} from "vant";
import Load from "../../../../components/load";
import Details from "../../../../components/Details.vue";
import TolWork from './TolWork'
import RankingWork from './RankingWork'

export default {
  data() {
    return {
      ActivityInfo: {},
      skip: 1,
      pageTol: 0,
      NotData: '',
      popular: false,
      data: []
    }
  },
  components: {
    Load,
    Details,
    TolWork,
    RankingWork
  },
  mounted() {
    let list = this.$refs.list
    let height = list.style.height
    this.NotData = 'line-height:' + height

    let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
    this.ActivityInfo = ActivityInfo

    let userInfo = JSON.parse(localStorage.getItem("user"))
    this.userInfo = userInfo;
    // judge the work is shared or not
    let Shared = JSON.parse(localStorage.getItem("Shared"));
    if (!Shared) {
      // open the work
      let WorkID = JSON.parse(localStorage.getItem("WorkID"));
      this.openWork(WorkID);
    }
  },
  methods: {
    init() {
      this.data = []
      this.skip = 1
      this.pageTol = 0
      this.fachdata()
      this.popular = false
    },
    openPopular() {
      this.popular = true
      this.data = []
      this.class = 2
      this.skip = 1
      this.pageTol = 0
      this.uppopularCol()
    },
    openWork(WorkID) {
      let that = this;
      if (WorkID) {
        let ActivityID = this.ActivityInfo.id

        WorkFindById({ActivityID, WorkID}).then(res => {
          if (res.errorCode == 200) {
            let payload = res.payload
            let length = payload.total
            if (length >= 1) {
              let WorkItem = payload.data[0];
              that.$refs.Details.openImg(WorkItem);
            }
          } else {
            Notify({type: 'warning', message: "找不到分享作品"});
          }
        });
        localStorage.setItem("Shared", true);
      }
    },
    //排行榜查询
    uppopularCol() {
      this.data = []
      let CompanyID = JSON.parse(localStorage.getItem("CompanyID"));
      let ActivityID = this.ActivityInfo.id
      let UserID = this.userInfo.id

      this.$refs.Load.open()
      popularFind({CompanyID, ActivityID,UserID}).then(res => {
        this.$refs.Load.close()

        let payload = res.payload
        if (payload.start == '正常') {
          let data = payload.data

          for (let i in data) {
            let Time = data[i].Time
            let day = new Date(Time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()

            let UserName = data[i].UserName
            if (UserName.length >= 5) {
              data[i].UserName = UserName.slice(0, 5) + '...'
            }

            let penName = data[i].PenName
            if (penName.length >= 5) {
              data[i].PenName = penName.slice(0, 5) + '...'
            }
          }

          this.data = data
        }else {
          Notify({type: 'warning', message:payload.start});
        }


      })
    },
    //列表普通查询
    fachdata(str) {
      let ActivityID = this.ActivityInfo.id
      let UserID = this.userInfo.id
      if (str !== 3) {
        this.data = []
        this.$refs.Load.open();
      }
      let pageIndex = this.skip
      let pageSize = 10

      WorkFind({UserID, ActivityID, pageIndex, pageSize}).then(res => {
        this.$refs.Load.close()
        if (res.errorCode == 200) {
          let payload = res.payload
          let number = payload.total
          number = Math.ceil(number / 10)
          this.pageTol = number
          let data = payload.data;
          for (let i in data) {
            let Time = data[i].Time
            let day = new Date(Time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()

            let UserName = data[i].UserName
            if (UserName.length >= 5) {
              data[i].UserName = UserName.slice(0, 5) + '...'
            }

            let penName = data[i].PenName
            if (penName.length >= 5) {
              data[i].PenName = penName.slice(0, 5) + '...'
            }
          }

          this.data = data
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }
      })
    },
    LastLoad() {
      if (this.skip == 1) {
        Toast('已经是第一页了～');
        return
      }
      this.skip--
      this.fachdata()
    },
    NextLoad() {
      if (this.skip == this.pageTol) {
        Toast('已经到底了～');
        return
      }
      this.skip++
      this.fachdata()
    },
  }
}
</script>

<style scoped lang="scss">
.list {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  //翻页器
  .addLoad {
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 13px;
    color: #000;

    display: flex;
    justify-content: space-around;

    .page {
      color: #973733;
      cursor: pointer;
      user-select: none;
    }

    .pages {
      color: #666666;
      cursor: pointer;
      user-select: none;
    }
  }

  .NotData {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #666666;
    text-align: center;

  }
}

.list::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.list::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}

.list::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #ededed;
}
</style>
