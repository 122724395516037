<template>
  <div>
    <div class="block" v-for="(item ,index) in isData" :key="index">
      <div class="img" @click="openImg(item)">
        <img :src="item.ImageUrl">
      </div>
      <div class="number">编号：{{ item.WorkNumber }}</div>
      <div class="user">作者：{{ item.UserName }}</div>
      <div class="title">标题：{{ item.PenName }}</div>
      <div class="time">时间：{{ item.Time }}</div>
      <div class="great" v-bind:class="{greats: item.CollectInShow}"><i
          @click="work(item.id)"></i><span class="CollectNumber">{{ item.Collect }}</span></div>
    </div>
    <Details ref="Details"></Details>
  </div>
</template>

<script>
import {setkork} from "../../../../api/exhibit";
import {Notify, Toast} from "vant";
import Details from "../../../../components/Details";

export default {
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem("user"))
    this.userInfo = userInfo
    let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
    this.ActivityInfo = ActivityInfo
  },
  props: {
    isData: []
  },
  data() {
    return {
      data: [],
      userInfo: {}
    }
  },
  components: {
    Details
  },
  methods: {
    // 打开详情
    openImg(item) {
      this.$refs.Details.openImg(item)
    },
    //点赞
    work(WorkID) {
      let ActivityID = this.ActivityInfo.id
      let UserID = this.userInfo.id
      let CompanyID = JSON.parse(localStorage.getItem("CompanyID"))

      setkork({WorkID, UserID, ActivityID, CompanyID}).then(res => {
        if (res.errorCode == 200) {
          Toast.success(res.payload);
          this.$parent.fachdata()
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.block {
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 5px;
  @mixin pos {
    position: absolute;
    font-size: 11px;
  }

  .img {
    position: absolute;
    width: 60px;
    height: 85px;
    left: 30px;
    top: 6px;
    overflow: hidden;
    border: 1px solid #f0f0f0;

    img {
      //transform: rotate(90deg);
      width: 100%;
      height: 100%;
    }
  }

  .number {
    @include pos;
    left: 100px;
    top: 10px;
  }

  .title {
    @include pos;
    left: 100px;
    top: 50px;
  }

  .time {
    @include pos;
    left: 100px;
    top: 70px;
  }

  .user {
    @include pos;
    left: 100px;
    top: 30px;
    width: 120px;
  }

  .great {
    @include pos;
    left: 271px;
    top: 10px;
    width: 75px;
    height: 25px;

    i {
      position: absolute;
      left: 0;
      top: 5px;
      display: inline-block;
      background-image: url("../../../../assets/image/create/like.png");
      width: 17px;
      height: 17px;
      background-size: 100% 100%;
    }

    .CollectNumber {
      position: absolute;
      left: 30px;
      top: 7px;
    }
  }

  .Evaluate {
    @include pos;
    right: 75px;
    top: 60px;

    i {
      margin-right: 10px;
      display: inline-block;
      background-image: url("../../../../assets/image/exhibit/openCom.png");
      width: 15px;
      height: 15px;
      background-size: 100% 100%;
    }
  }

  .greats {
    i {
      background-image: url("../../../../assets/image/create/likes.png");
    }
  }

  .step {
    @include pos;
    left: 271px;
    top: 36px;


    i {
      margin-right: 10px;
      display: inline-block;
      background-image: url("../../../../assets/image/create/step.png");
      width: 17px;
      height: 17px;
      background-size: 100% 100%;
      float: left;

    }

    span {
      float: left;
      display: inline-block;
    }
  }

  .steps {
    i {
      float: left;
      background-image: url("../../../../assets/image/create/steps.png");
    }
  }
}
</style>