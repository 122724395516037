<template>
  <div id="barrage">

  </div>
</template>

<script>
import AwesomeDanmaku from 'awesome-danmaku'
import {findComment} from '../api/exhibit'

export default {
  data() {
    return {
      data: []
    }
  },
  beforeDestroy() {
    if (this.data.length >= 1) {
      this.maps.stop()
      clearInterval(this.set)
    }

    setTimeout(() => {
      location.reload()
    }, 10)
  },
  mounted() {
    this.maps = AwesomeDanmaku.getPlayer({
      el: '#barrage',
      maxCount: 100,
      trackCount: 100,
      nodeMaxCount: 100,
      rollingTime: 5000
    });
    this.getdata()
    this.maps.play()
  },
  methods: {
    getdata() {
      let CompanyID = JSON.parse(localStorage.getItem("CompanyID"))
      let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
      this.ActivityInfo = ActivityInfo
      let ActivityID = ActivityInfo.id



      if (this.data.length >= 1) {
        clearInterval(this.set)
      }
      findComment({CompanyID,ActivityID}).then(res => {
        let data = res.payload
        if (data.length >= 1) {
          this.data = data
          this.set = setInterval(() => {
            for (let item in this.data) {
              this.maps.insert({value: data[item].Data, color: '#ffffff', fontSize: 18});
            }
          }, 1000)
        }
      })
    },

    pay() {
      this.maps.play()
    },
    stop() {
      this.maps.stop()
    },
  }
}
</script>

<style scoped lang="scss">
p {
  font-size: 14px !important;
}
</style>
