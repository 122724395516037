<template>
  <div class="exhibit">
    <Header class="Header" ref="Header"></Header>
    <div class="viewMap" :style="bgImg">
      <Danmaku ref="Danmaku" class="Danmaku" v-bind:class="{coyBg:!commentStyle}"></Danmaku>
    </div>
    <div class="nav">
      <div class="search" @click="openSearch"></div>
      <div class="barrage" v-if="commentStyle" @click="opencomment"></div>
      <div class="barrages" v-else @click="opencomment"></div>

      <div class="input">
        <input type="text" placeholder="请输入弹幕" v-model="comment"/>
        <span @click="setcomment">发送</span>
      </div>
    </div>

    <div class="navs" v-if="!searchInit">
      <div class="block right" v-bind:class="{highlight:popular=='1'}" @click="openPopular(1)">全部作品</div>
      <div class="block" v-bind:class="{highlight:popular=='2'}" @click="openPopular(2)">热门排行</div>
      <div class="block" v-bind:class="{highlight:popular=='5'}" @click="openPopular(5)">彩蛋排行榜</div>
      <!--      <div class="introduce" @click="openIntroduce" v-bind:class="{introduces:popular=='4'}">活动介绍</div>-->
    </div>
    <div class="navs" v-if="searchInit">
      <div class="Reset" @click="ResetSearch">重置</div>
    </div>
    <List ref="List" :style="listheight" v-if="!searchInit&&popular=='1'||popular=='2'"></List>
    <SearchList ref="SearchList" v-if="searchInit&&popular=='3'" :style="listheight"></SearchList>
    <Introduce ref="Introduce" :style="listheight" v-if="popular=='4'"></Introduce>
    <PrizeRanking ref="PrizeRanking" v-if="popular=='5'" :style="listheight"></PrizeRanking>
    <Search ref="Search"></Search>
    <GuidePage ref="GuidePage"></GuidePage>
    <MessagePage ref="MessagePage"></MessagePage>
  </div>
</template>

<script>
import Header from "../../components/Header";
import List from "./com/List/List";
import Danmaku from "../../components/Danmaku";
import Introduce from './com/Introduce'
import Search from './com/Search/index'
import SearchList from './com/Search/SearchList'
import GuidePage from './com/GuidePage.vue'
import PrizeRanking from './com/PrizeRanking.vue'
import {addComment} from "../../api/exhibit";
import MessagePage from './com/MessagePage.vue'

import {Toast} from "vant";

export default {

  data() {
    return {
      ActivityInfo: '',

      searchStart: 0,
      listheight: '',
      titleNumber: '',

      search: [],
      searchInit: false,
      searchView: false,
      searchData: '',
      popular: 1,
      popularData: [],
      list: [],

      commentStyle: true,
      bgImg: '',
      comment: '',
    }
  },
  created() {
    let ActivityInfo = localStorage.getItem("ActivityInfo")

    this.ActivityInfo = JSON.parse(ActivityInfo)

    this.init();
    let listheight = document.documentElement.clientHeight - 60 - 250 - 55 - 30 - 5
    listheight = 'height:' + listheight + 'px'
    this.listheight = listheight
    this.openPopular(1)
  },
  methods: {
    openSearchList(data) {
      this.popular = '3'
      this.searchInit = true
      setTimeout(() => {
        this.$refs.SearchList.setData(data)
      }, 300)
    },
    ResetSearch() {
      this.searchStart = '1'
      this.searchData = ''
      this.searchInit = false
      this.openPopular(1)
    },
    openIntroduce() {
      this.popular = 4
    },
    openSearch() {
      this.$refs.Search.open()
    },
    openPopular(str) {
      if (str == 1) {
        this.popular = 1
        this.searchStart = '0'
        setTimeout(() => {
          this.$refs.List.init()
        }, 100)
      } else if (str == 2) {
        this.popular = 2
        setTimeout(() => {
          this.$refs.List.openPopular()
        }, 100)
      } else if (str == 5) {
        this.popular = 5
      }
    },
    init() {
      let ActivityInfo = this.ActivityInfo

      let bg = ''
      if (ActivityInfo.RenderingImg) {
        bg = `background-image: url(${ActivityInfo.RenderingImg})`
      } else {
        bg = `background-image: url(${ActivityInfo.BgImg})`
      }

      this.bgImg = bg
    },
    initcanvas() {
      let titleNumber = this.titleNumber
      this.$refs.Canvas.openViews(titleNumber)
    },
    //开启/停止弹幕
    opencomment() {
      let commentStyle = this.commentStyle
      if (commentStyle) {
        this.commentStyle = false
      } else {
        this.commentStyle = true
      }
    },
    //发送弹幕
    setcomment() {
      let userInfo = JSON.parse(localStorage.getItem("user"));
      let UserID = userInfo.id;
      let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
      this.ActivityInfo = ActivityInfo
      let ActivityID = ActivityInfo.id

      let Data = this.comment
      let CompanyID = localStorage.getItem("CompanyID");

      if (Data) {
        addComment({Data, UserID, CompanyID, ActivityID}).then(res => {
          if (res.errorCode == 200) {
            this.$refs.Danmaku.getdata()
            Toast.success(res.payload);
            this.comment = ''
          } else {
            Toast.fail(res.errorMsg);
          }
        })
      } else {
        Toast.fail('弹幕内容未填写');
      }
    },
  },
  components: {
    Header,
    List,
    Danmaku,
    Introduce,
    Search,
    SearchList,
    GuidePage,
    PrizeRanking,
    MessagePage
  },
}
</script>

<style lang="scss">
.exhibit {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  //display: flex;
  //flex-flow: column;
  .viewMap {
    width: 100%;
    height: 250px;
    position: relative;
    background-color: #f0f0f0;
    background-size: 100% 100%;

    .genBgUrl {
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
    }

    .Danmaku {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    .coyBg {
      opacity: 0;
    }
  }

  .nav {
    width: 100%;
    height: 55px;
    font-size: 14px;
    color: #898989;
    background-color: #FFFFFF;
    position: relative;

    .search {
      width: 20px;
      height: 20px;
      background-image: url("../../assets/image/exhibit/search.png");
      background-size: 100% 100%;
      position: absolute;
      left: 25px;
      top: 50%;
      margin-top: -10px;
      cursor: pointer;
      user-select: none;
    }

    .barrage {
      width: 28px;
      height: 23px;
      background-image: url("../../assets/image/exhibit/barrage.png");
      background-size: 100% 100%;
      position: absolute;
      left: 70px;
      top: 50%;
      margin-top: -11.5px;
      cursor: pointer;
      user-select: none;
    }

    .barrages {
      width: 28px;
      height: 23px;
      background-image: url("../../assets/image/exhibit/barrages.png");
      background-size: 100% 100%;
      position: absolute;
      left: 70px;
      top: 50%;
      margin-top: -11.5px;
      cursor: pointer;
      user-select: none;
    }

    span {
      width: 100px;
      text-align: center;
      display: inline-block;
    }

    .input {
      width: 250px;
      position: absolute;
      height: 28px;
      right: 10px;
      top: 50%;
      margin-top: -14px;

      input {
        position: absolute;
        right: 80px;
        top: 0;
        line-height: 28px;
        width: 170px;
        height: 100%;
        padding: 0;
        border: none;
        font-size: 14px;
        text-indent: 1rem;
        background-color: #f1f1f1;
      }

      span {
        display: inline-block;
        background-color: #1989fa;
        color: #FFFFFF;
        width: 70px;
        font-size: 12px;
        height: 28px;
        line-height: 28px;
        position: absolute;
        right: 0;
        cursor: pointer;
        user-select: none;
      }
    }
  }

  .navs {
    width: 100%;
    height: 30px;
    font-size: 14px;
    color: #898989;
    margin-bottom: 5px;
    background-color: #FFFFFF;
    position: relative;

    .Reset {
      width: 80px;
      height: 20px;
      line-height: 20px;
      border: 1px solid #B8B8B8;
      position: absolute;
      text-align: center;
      left: 10px;
      top: 5px;
    }

    .block {
      margin-left: 10px;
      margin-right: 10px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      display: inline-block;
      cursor: pointer;
      user-select: none;
    }

    .introduce {
      width: 80px;
      height: 20px;
      line-height: 20px;
      border: 1px solid #B8B8B8;
      position: absolute;
      text-align: center;
      right: 10px;
      top: 5px;
    }

    .introduces {
      background-color: #1989fa;
      color: #FFFFFF;
      font-size: 11px;
    }

    .right {
      margin-right: 15px;
    }

    .highlight {
      border-bottom: 3px solid #1989fa;
    }
  }

  .keyWords {
    width: 100%;
    border-bottom: 1px solid rgba(159, 159, 159, 0.5);
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .block {
      font-size: 13px;
      color: #505050;
      background-color: #FFFFFF;
      border: 1px solid #f0f0f0;
      text-align: center;
      min-width: 110px;
      height: 30px;
      line-height: 30px;
      margin: 5px;
      margin-bottom: 12px;

      i {
        width: 30px;
        height: 100%;
        background-size: 10px 10px;
        background-image: url("../../assets/image/exhibit/close.png");
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        float: right;
      }
    }
  }
}
</style>
